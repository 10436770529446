@import 'styles/includes.scss';

.HomePage {
    flex: 1;
    @media screen and (-ms-high-contrast: active),
        screen and (-ms-high-contrast: none) {
        flex: none;
    }

    &__Row {
        padding-top: 1.6rem;

        @include media(m) {
            padding-top: 5.4rem;
        }
    }
}
